<template>
  <div class="index" v-loading="pageLoading" ref="index">
    <el-dialog :title="videoTitle" :visible.sync="videoShow" @close="videoClose" width="751px">
      <video :src="videoUrl" controls ref="videoPlayer" id="player" style="width:711px; height:400px;object-fit:cover;"></video>
    </el-dialog>
    <div class="index-main">
      <div class="classification">
        <ul class="list">
         
          <li
            class="list-item"
            v-for="(item, index) of category"
            :key="index"
            @click="toList('lessonTuoyu', item.id)"
          >
            <span v-if="item.name.length < 11">
              {{ item.name }}
            </span>
            <span v-else>
              {{ item.name.slice(0, 10) }}<br />{{ item.name.slice(10) }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </li>
          <!-- <li class="list-item" @click="jumpList('liveTuoyu')">
            <span> 直播回放</span>
            <i class="el-icon-arrow-right"></i>
          </li> -->
          <!-- <li class="list-item" @click="tojiazhang()">
            <span> 育儿学堂</span>
            <i class="el-icon-arrow-right"></i>
          </li> -->
        </ul>
      </div>
      <el-carousel :interval="3000" arrow="hover" height="372px" class="banner">
        <el-carousel-item v-if="banner.length == 0">
          <div class="banner-item">
            <img :src="$logoBig" />
          </div>
        </el-carousel-item>
        <el-carousel-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            height="372"
            :src="item.cover"
            fit="cover"
            @click="jumpBannerLesson(item)"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="userInfo" v-if="isLogin">
        <div class="userLogo">
          <img
            :src="
              (user && user.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'
            "
          />
          <span>{{ user ? user.nickname : "用户昵称" }}</span>
        </div>
        <div class="studyTime">
          <p>
            今日学习<b>{{ studyInfo.today_time }}</b
            >分钟
          </p>
          <p>
            连续学习<b>{{ studyInfo.day_number }}</b
            >天
          </p>
        </div>
        <p class="btn" @click="jumpList('myCenter')">个人中心</p>
      </div>
      <div class="noLogin" v-else>
        <div class="btn">
          <p @click="$store.commit('changeLoginShow', true)">登录</p>
          <span></span>
          <p @click="$store.commit('changeLoginShow', true)">注册</p>
        </div>
      </div> -->
    </div>
    <!-- <listCat
      v-for="(item,index) in category" v-if="item.list.length" :key="item.id"
      :list="item.list"
      :name="item.name"
      :imgUrl="item.cover"
      :id="item.id"
    ></listCat> -->
    <div class="module-title">
        <p>最新课程</p>
      </div>
    <list
      :list="newList.slice(0,4)"
      ></list>
    <div v-for="(item,index) in category" v-if="item.list.length" :key="item.id">
      <!-- <listCat
       v-if="item.list[0].list"
        :list="item.list"
        :name="item.name"
        :imgUrl="item.cover"
      ></listCat> -->
      <list
        v-if="item.list.length > 1"
        :list="item.list.slice(0,4)"
        :name="item.name"
        :imgUrl="item.cover"
      ></list>
      <IndexImgLeft v-if="item.list.length ==1" :list="item.list"
        :name="item.name"
        :imgUrl="item.cover">

      </IndexImgLeft>
      <div class="moreBtn"><p @click="toList('lessonTuoyu',item.id)">查看更多课程<van-icon name="arrow" color="#2e66d0" size="16" /></p></div>
    </div>
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};
import list from "@/components/IndexList";
import IndexListImg from "@/components/IndexListImg";
import listCatNum3 from "@/components/IndexListCatNum3";
import listCat from "@/components/IndexListCat";
import IndexImgLeft from "@/components/indexImgLeft";
export default {
  name: "index",
  components: { listCatNum3, listCat, list, IndexListImg, IndexImgLeft },
  data() {
    return {
      pageLoading: true,
      newList:[],
      img:[
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/IndexTitle1.png',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg',
        'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E4%B8%AD%E6%89%98%E8%AE%B2%E5%9D%9B-%E9%A6%96%E9%A1%B5-%E7%AC%AC%E4%B8%89%E7%89%88_03.png'
      ],
      lesson1:[],
      lesson2:[],
      lesson3:[],
      lesson4:[],
      
      category: [],
      banner: [],
      videoShow: false,
      videoUrl:'',
      videoTitle:'',
      width: 1200,
    };
  },
  async mounted() {
    // this.$store.dispatch("getStudy");
    this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
    await this.getData();
    // this.$nextTick(()=>{
    //   this.width = this.$refs.index.offsetWidth

    // })
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  watch: {
    plat() {
      // this.$store.dispatch("getStudy");
      // this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
      this.getData();
    },
  },
  methods: {
    tojiazhang() {
   
    },
    // 去列表/去详情
    toList(url, id, index) {
      if(url=='detail2' && (id=='745' || id=='746')){
        this.$toast('敬请期待');
        return
      }

      let myUrl = `/${this.$route.params.plat_type}/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    jumpList(name) {
      this.$until.toPageOpen("/" + name);
      // this.$router.push(`./${name}`);
    },
    jumpBannerLesson(item) {
      // item.category_id==14 && 
      if(item.category_id==14 && item.video_url){
        this.videoShow = true
        this.videoUrl = item.video_url
        this.videoTitle = item.name
        this.$nextTick(()=>{
          this.$refs.videoPlayer.play();
        })
      }else if (item.url) {
        if(item.url.indexOf('http')==-1){
          this.$until.toPageOpen(`/detail?id=${item.url}`);
        }else{
          window.open(item.url)
        }
        // this.$until.toPageOpen(`/detail2?id=${item.url}`);
      }
    },
    videoClose(){
      this.$refs.videoPlayer.pause();
      this.videoShow = false   
    },
    jumpIdea(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    async getData() {
      this.pageLoading = true;
     
      let res = await this.$api({
        method: "get",
        url: "/api/index?flat_id="+this.plat,
      });
      this.pageLoading = false;
      console.log(res)
      if (res.code == 0) {
        this.banner = res.data.banner || [];
        this.category = res.data.parent_list || [];
        this.newList = res.data.new_list || []
        // 职业教育 207
        let lesson1 =  this.category.find((item) => item.id == 374)
        
          if(lesson1){
            this.lesson1 = lesson1.list
            // this.jnLesson = jnLesson.list.filter((v) => v.list[0].length)
            //                 .map((vv) => vv.list[0])
            //                 .flat();
          }

          // 高级 238
          let lesson2 =  this.category.find((item) => item.id == 375)
          if(lesson2){
            this.lesson2 =lesson2.list
            // this.lesson1 =lesson1
            //   .list.filter((v) => v.list[0].length)
            //   .map((vv) => vv.list[0])
            //   .flat();
          }
        

           // 深度 238
           let lesson3 = this.category.find((item) => item.id == 376)
          if(lesson3){
            this.lesson3 = lesson3.list
            // this.lesson1 = lesson2
            //     .list.filter((v) => v.list[0].length)
            //     .map((vv) => vv.list[0])
            //     .flat();
          }
          let lesson4 = this.category.find((item) => item.id == 377)
          if(lesson4){
            this.lesson4 = lesson4.list
          }
      } else {
        this.$toast(res.msg);
      }
    },
    liveplay(item) {
      if (!this.$until.loginRequire()) {
        return;
      }
      let { name, video_url, id, video_id, goods_id } = item;

      if (video_url || video_id) {
        this.$store.commit("SET_VIDEO", null);
        let url =
          window.location.origin +
          window.location.pathname +
          `#/player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(
            video_url
          )}&from=直播&goods_id=${goods_id}&type=ali`;
        this.$until.toPageOpen(url);
      } else {
        this.$toast("没有视频");
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body {
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
$themeColor: #2e66d0;
.index {
  width: 100%;
  padding-bottom: 30px;
  .moreBtn{
    margin: 50px auto 70px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $themeColor;
    justify-content: center;
    
    p{
      text-align: center;
      cursor: pointer;
      background: #fff;
      border-radius: 3px;
      padding: 10px 0;
      width: 350px;
    }
  }
  .index-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 372px;
    // align-items: flex-start;

    .classification {
      flex-shrink: 0;
      width: 220px;
      height: 100%;
      box-shadow: 0 5px 10px rgb(51 51 51 / 6%);
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      .list {
        padding: 8px 0;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        li {
          flex: 1;
          span {
            font-weight: bold;
            display: block;
          }
        }
        .list-item {
          position: relative;
          width: 100%;
          height: 37px;
          line-height: 24px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 10px;
          color: #333;
          font-size: 16px;
          box-sizing: border-box;

          &:hover {
            background: #edf8ff;
            color: $themeColor;
          }
          i {
            color: #c6c6c6;
          }
        }
      }
    }
    .userInfo {
      margin-left: 9px;
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userBg.jpg");
      display: flex;
      flex-direction: column;
      align-items: center;
      .userLogo {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 10px;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 5px;
        }
        span {
          font-size: 16px;
          color: 393939;
          // font-family: "黑体";
        }
      }
      .studyTime {
        margin-bottom: 80px;
        p {
          font-size: 12px;
          width: 122px;
          display: flex;
          height: 58px;
          align-items: center;
          b {
            flex: 1;
            font-size: 22px;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      .btn {
        cursor: pointer;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: $themeColor;
      }
    }
    .noLogin {
      margin-left: 29px;
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userNoLogin.png");
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        margin-top: 296px;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: $themeColor;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        span {
          width: 1px;
          height: 15px;
          margin: 0 10px;
          display: block;
          background: #fff;
        }
        p {
          cursor: pointer;
        }
      }
    }
  }

  .banner {
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
    height: 100%;
    background: #f7f7f7;
    .banner-item {
      border-radius: 10px;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: auto;
        height: auto;
      }
    }
  }

  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-family: "SourceHanSans";
    }
    span {
      color: #144094;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .live {
    .course-card-title {
      margin-bottom: 20px !important;
      p {
        height: 48px !important;
        -webkit-line-clamp: 2 !important;
        font-size: 16px !important;
      }
    }
  }
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        flex-shrink: 0;
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail {
      padding: 16px;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .course-card-title {
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        height: 80px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          color: #333333;
          font-size: 16px;
        }
        .des {
          margin-top: 5px;
          color: #7f7f7f;
          font-size: 14px;
        }
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }

  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 4px;
      line-height: 34px;
      height: 34px;
      color: $themeColor;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 14px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: $themeColor;
      }
    }
  }
 

}

</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
