<template>
    <div class="category idea">
      <div class="module-title" v-if="name">
        <img
          :src="imgUrl"
        />
        <p>{{name}}</p>
        <span v-if="id" @click="toList('LessonTuoyu', id)"
          >更多
          <van-icon name="arrow" color="#144094" size="16" />
        </span>
      </div>
      <div class="tab" v-if="list.length > 1">
        <p
          v-for="(item, index) of list"
          :class="{ active: index == vipIndex }"
          @click="vipIndex = index"
          :key="item.id"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="course-item" style="flex-wrap: wrap">
        
        <div
          class="course-card levelItem"
          style="width: calc((100% - 60px) * 0.25)"
          v-for="card of list[vipIndex].list[0].slice(0, 4)"
          :key="card.id"
          @click="jumpLesson(card)"
        >
          <div
            class="course-card-cover"
            :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="card.is_free == 2" />
              <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <div class="tag" v-if="tag">
              {{ tag }}
            </div>
            <van-image
              class="course-cover"
              width="100%"
              :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              :src="card.cover"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="$placeImg"
                ></van-image>
              </template>
            </van-image>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p class="ellipsis">{{ card.name }}</p>
            </div>
            <p class="des ellipsis_three">{{ card.description }}</p>

            <!-- <p class="price">
              ￥<b>{{ card.price }}</b
              ><span>￥{{ card.express_price }}</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "index",
  props:['list', 'name', 'imgUrl','id','tag'],
  data() {
    return {
      vipIndex: 0,
      width: 1200
    };
  },
  async mounted() {
    
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    
  },
  methods: {
   // 去列表
   toList(url, id, index) {
      let myUrl = `/${this.$route.params.plat_type}/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
      
    },
  
    jumpLesson(item) {
      console.log('---',this.plat)
      if(this.$until.loginRequire()){
        this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail?id=${item.id}`)
        // if(this.plat == '373')this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail?id=${item.id}`);
        // if(this.plat == '379')this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail2?id=${item.id}`);
      }
      // this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
   
  },
};
</script>

<style lang="scss" scoped>
  
  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-weight: bold;
      font-family: "SourceHanSans";
    }
    span {
      color: #144094;
      font-size: 16px;
      cursor: pointer;
    }
  }

  
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .tag{
          position: absolute;
          bottom: 10px;
          right: 10px;
          z-index: 1;
          width: 40px;
          height: 20px;
          border-radius: 2px;
          text-align: center;
          line-height: 20px;
          background-color: rgba($color: #000000, $alpha: 0.5);
          color: #fff;
          font-size: 12px;
        }
      .course-cover {
        padding-bottom: 0;
        display: block;
        flex-shrink: 0;
        
      }
    }
    .course-card-detail {
      padding: 16px;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .des {
          font-size: 14px;
          margin-top: 5px;
          color: #7f7f7f;
        }
      .course-card-title {
        line-height: 24px;
        margin-bottom: 4px;
        font-size: 16px;
        p:first-of-type{
          font-weight: bold;
        }
        p {
          width: 100%;
         
          color: #333333;
          font-size: 16px;
        }
        
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
    .course-card {
      height: 304px;
      margin-right: 20px;
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
    .course-card:nth-of-type(5n) {
      margin: 0;
    }
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }

  .idea,
  .cert {
    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      margin-right: 20px;
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;
      height: 216px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0px 0px 10px #0000000f;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 12px 12px 0 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        
        .tip {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 1;
          img {
            width: 78px;
            height: 35px;
          }
        }
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #0080d6;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #0080d6;
        }
      }
    }
  }

  
  .category {
    .tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        height: 40px;
        flex: 1;
        text-align: center;
        line-height: 40px;
        margin-right: 20px;
        display: inline-block;
        font-size: 14px;
        border-radius: 10px;
        cursor: pointer;
        color: #454545;
        background: #f2f2f2;
        &:hover {
          background: #2e66d0;
          color: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: #2e66d0;
        color: #fff;
      }
    }
  }

</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
